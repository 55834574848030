<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";

import UpdateButton from "@/components/buttons/update-button";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        UpdateButton,
    },
    data() {
        const id = this.$route.params.id;

        return {
            id: id,
            title: `Admin Edit Password`,
            items: [
                {
                    text: "Admin"
                },
                {
                    text: 'Edit Password',
                    active: true,
                },
            ],
            data: {
                password: '',
                password_confirmation: '',
            },

            errors: {},
        };
    },
    computed: {

    },
    async mounted() {
        // console.log(this.$helloworld);
    },
    methods: {
        async update() {
            this.errors = {};

            const requestData = this.data;
            const result = await axios.put(`${process.env.VUE_APP_APIURL}/admin/${this.id}/password`, requestData, {
                headers: authHeader(),
            });

            if (result) {
                if (result.data['code'] === 'success') {
                    this.$refs.messageModal.showModal('The password has been updated successfully');
                    this.data.password = '';
                    this.data.password_confirmation = '';
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <div class="row mt-2">
                            <div class="col-12 col-md-6 mb-2">
                                <InputGroup type="password" id="password" displayName="Password" v-model="data.password" :error="errors.password" />
                                <InputGroup type="password" id="password_confirmation" displayName="Password Confirmation" v-model="data.password_confirmation" :error="errors.password" />
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <UpdateButton @click="update" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</Layout></template>